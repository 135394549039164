import React from 'react';
import DataTableCard from 'components/DataTableCard';
import strftime from 'strftime';



type TodayProps = {
    //partner : string | string[]
    start? : Date
    end? : Date
    //periodSubtitle? : string

}

function Today(props:TodayProps) {

    const startDate = props.start ? strftime("%Y-%m-%d", props.start) : ""
    const endDate = props.end ? strftime("%Y-%m-%d", props.end) : ""


    return (
        <DataTableCard url="today/" 
            aux_url_params={ {periodSubtitle:"activity  ",start:startDate, end:endDate} }
            title="Partner Activity Summary" 
            subtitle="" 
            partner=""
        />
    )
}

export default Today;
