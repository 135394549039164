import React,{useState} from 'react';
import { MainMenu } from 'components/SideMenu/menus';
import Page from "components/Page";
import Today from 'components/wg//Today';
import DateRangeFilter from 'components/DateRangeFilter';



function ActivitySummary(){
        const [startDate, setStartDate] = useState<Date|undefined>(new Date());
        const [endDate, setEndDate] = useState<Date|undefined>(new Date());
    
    return (
        <Page menu={MainMenu} current="Today">

             <h3 className="page-title">Activity Summary</h3>
             <DateRangeFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />

            <Today start={startDate} end={endDate} />
        </Page>
    )
}



export default ActivitySummary;


