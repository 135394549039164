import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import SportsGymnasticsOutlinedIcon from "@mui/icons-material/SportsGymnasticsOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import PeopleIcon from '@mui/icons-material/People';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import {TMenu} from "./types";

export const MainMenu:TMenu = [
  {title:'Main', icon:<AccountBalanceOutlinedIcon/>, link: `/` },
  null,
  //{title:'B2C Funnel', icon:<FilterAltOutlinedIcon/>, link: `/b2c-funnel` },
  {title:'Multi-Partner Stats', icon:<FilterAltOutlinedIcon/>, link: `/p/x/ex` },

  null,
  {title:'Demo Site Visitors', icon:<GroupOutlinedIcon />, link:"/demo/users" },
  {title:'Recordings', icon:<VideoCameraBackOutlinedIcon />, link:"/recordings" },
  {title:'Monitoring', icon:<MonitorHeartIcon />, link:"/monitoring" },

  {title:'Now', icon:<SportsGymnasticsIcon />, link:"/now" },
  {title:'Today', icon:<SportsGymnasticsIcon />, link:"/today" },

  null,
  {title:'Workouts', icon:<FitnessCenterOutlinedIcon />, link:"/workouts" },
  {title:'Exercises', icon:<SportsGymnasticsOutlinedIcon />, link:"/exercises" },
  {title:'Sessions', icon:<AssignmentIndOutlinedIcon />, link:"/sessions" },
  {title:'Problems', icon:<ErrorOutlineOutlinedIcon />, link:"/problems",
    submenu: [
      {title:'Critical Errors', subtitle:'Black Screen Exception', icon:<NewReleasesOutlinedIcon fontSize="small" />, link:"/problem1" },
      {title:'Skeleton problem', subtitle:'testModel <> 38', icon:<GppBadOutlinedIcon fontSize="small" />, link:"/problem2" },
      {title:'Skeleton disapper', subtitle:'Mor', icon:<GppMaybeOutlinedIcon fontSize="small" />, link:"/problem3" },
      {title:'Kemtapi errors', icon:<AssignmentLateOutlinedIcon fontSize="small" />, link:"/problem4" }
    ]
  }
]

export function PartnerPageMenu(partner : string) : TMenu {
  const menu = [
    {title:'Main', icon:<AccountBalanceOutlinedIcon/>, link: `/` },
    null,
    {title:'Funnel',     icon:<FilterAltOutlinedIcon/>   , link: `/p/${partner}/` },
    {title:'User Stats', icon:<GroupOutlinedIcon/>       , link: `/p/${partner}/user-stats`},
    {title:'Adherence', icon:<EmojiPeopleOutlinedIcon /> , link: `/p/${partner}/adherence` },
    {title:'Model and GPU', icon:<AssessmentOutlinedIcon />, link: `/p/${partner}/gpu` },
    {title:'Exercise Statistics', icon:<AssessmentOutlinedIcon />, link: `/p/${partner}/ex` },
  ] 
  if (partner.includes('@care')) {
    menu.push(
        null,
        {title:'PT Stats', icon:<PeopleIcon />, link: `/p/${partner}/pt` },
    )
  }
  menu.push(
    null,
    {title:'Users', icon:<AssignmentIndOutlinedIcon />, link: `/users/${partner}` }
  )
  return menu
}

export function XPartnerPageMenu() : TMenu {
    return [
      {title:'Main', icon:<AccountBalanceOutlinedIcon/>, link: `/` },
      null,
      {title:'User Stats', icon:<GroupOutlinedIcon/>       , link: `/p/x/user-stats`},
      {title:'Exercise Statistics', icon:<AssessmentOutlinedIcon />, link: `/p/x/ex` },  
      {title:'Model and GPU', icon:<AssessmentOutlinedIcon />, link: `/p/x/gpu` },

    ]
  }
  

export function BTCPageMenu(partner : string) : TMenu {
    return [
        {title:'Main', icon:<AccountBalanceOutlinedIcon/>, link: `/` },
        null,
        ]
}
  
  


export const CareManageMenu : TMenu =  [
      {title:'Main', icon:<AccountBalanceOutlinedIcon/>, link: `/` },
      null,
      {title:'Users', icon:<AssignmentIndOutlinedIcon />, link: `/p/care-manage` }
    ]
  
  
  

export const voriMenu : TMenu = [
      {title:'Funnel',     icon:<FilterAltOutlinedIcon/>   , link: "/p/vori/" },
      {title:'User Stats', icon:<GroupOutlinedIcon/>       , link: `/p/vori/user-stats`},
      {title:'Adherence', icon:<EmojiPeopleOutlinedIcon /> , link: `/p/vori/adherence` },
      null,
      {title:'Users', icon:<AssignmentIndOutlinedIcon />, link: `/users/vori` }
]
