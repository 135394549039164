
import {Router} from "router/Router";

import Login from 'pages/auth/Login';
import UnvalidatedEmail from 'pages/auth/UnvalidatedEmail'
import UnassignedUser   from 'pages/auth/UnassignedUser'
import Loading   from 'pages/auth/Loading'

import MainPage from 'pages/MainPage';

import Logout from 'components/Logout';
import Users         from 'pages/Users';
import UsersSessions from 'pages/UserSessions';
import Session from 'pages/Session';
import Story from 'pages/Story';
import { SearchPage } from 'pages/Search';
import Workouts  from 'pages/Workouts';
import Exercises  from 'pages/Exercises';
import Sessions, {SessionsSearchResults as ProblemSessions} from 'pages/Sessions';
import QuestionnaireFunnel from 'pages/QuestionnaireFunnel';
import ExHistory from 'pages/ExHistory';
import DemoUsers from 'pages/DemoUsers';
import Recordings from 'pages/Recordings';
import Monitoring from 'pages/Monitoring';

import defineVoriRoutes from 'pages/partners/vori/pages';
import { FunnelPage,LattusFunnelPage } from 'pages/PartnerPage/FunnelPage';
import { B2CFunnelPage } from 'pages/PartnerPage/B2CFunnelPage';

import { UserStatsPage } from 'pages/PartnerPage/UserStatsPage';
import { UserStatsPageX } from 'pages/PartnerPage/UserStatsPageX';

import { Adherence } from 'pages/PartnerPage/Adherence';
import PartnerPageGPU from 'pages/PartnerPageGPU';
import { CareManageMenu } from 'components/SideMenu/menus';
import ActiveSessions from 'pages/now/ActiveSessions'
import ExerciseStats from "pages/ExerciseStats";
import UsersB2C from "pages/Users/UsersB2C";
import ExerciseStatsX from "pages/ExerciseStatsX";
import PartnerPageGPUX from "pages/PartnerPageGPUX";
import { PTStats } from "pages/PartnerPage/PTStats";
import ActivitySummary from "pages/now/ActivitySummary";


export function defineRoutes(Router:Router) {

Router.route("*","/login", Login)
Router.route("*","/logout", Logout)
Router.route("*","/loading", Loading, {}, {showURL:false})

Router.route("unvalidated","*", UnvalidatedEmail)
Router.route("unassigned","*",  UnassignedUser)
Router.route("unloggedin","*",  Login)


Router.route("kemtai","/search", SearchPage)
Router.route("kemtai","/workouts", Workouts)
Router.route("kemtai","/exercises", Exercises)
Router.route("kemtai","/sessions", Sessions)

Router.route("kemtai","/problem1", ProblemSessions, {url:"problem1", page:'Critical Errors'})
Router.route("kemtai","/problem2", ProblemSessions, {url:"problem2", page:'Skeleton problem'})
Router.route("kemtai","/problem3", ProblemSessions, {url:"problem3", page:'Skeleton disapper'})
Router.route("kemtai","/problem4", ProblemSessions, {url:"problem_kemtapi_error", page:'Kemtapi errors'})

Router.route("kemtai","/p/kemtapi/",     FunnelPage,        {partner:"kemtapi",  type:"kemtapi"})
Router.route("kemtai","/p/vori/",        FunnelPage,        {partner:"vori",     type:"kemtapi"})
Router.route("kemtai","/p/doron/",       FunnelPage,        {partner:"doron",    type:"kemtapi"})

Router.route("kemtai","/p/kemtai",  FunnelPage, {partner:"kemtai",  type:"app", startDate: new Date("2022-05-01")})
Router.route("kemtai","/p/gympass", FunnelPage, {partner:"gympass", type:"app", startDate: new Date("2022-05-01")})

Router.route("kemtai","/p/icura",   FunnelPage, {partner:"icura", type:"app", startDate: new Date("2022-05-01")})
Router.route("kemtai","/p/upswing-health", FunnelPage, {partner:"upswing-health", type:"app", startDate: new Date("2022-05-01")})
Router.route("kemtai","/p/moveo-start", FunnelPage, {partner:"moveo-start", type:"app", startDate: new Date("2022-05-01")})
Router.route("kemtai","/p/upmc-sports-med", FunnelPage, {partner:"upmc-sports-med", type:"app", startDate: new Date("2022-05-01")})
Router.route("kemtai","/p/latus-health", LattusFunnelPage, {partner:"latus-health", type:"app", startDate: new Date("2022-05-01")})

Router.route("kemtai","/p/care-manage/", Users , {partner:"care-manage",  menu:CareManageMenu})

Router.route("kemtai","/p/kemtapi/user-stats", UserStatsPage,     {partner:"kemtapi",  type:"kemtapi"})

Router.route("kemtai","/b2c-funnel",  B2CFunnelPage,  {partner:"spinenmore@care",  type:"b2c", startDate: new Date("2023-08-01")})


Router.route("kemtai","/p/:partner/",     FunnelPage,  )

Router.route("kemtai","/p/x/user-stats", UserStatsPageX)
Router.route("kemtai","/p/:partner/user-stats", UserStatsPage)
Router.route("kemtai","/p/:partner/adherence", Adherence)
Router.route("kemtai","/p/:partner/pt", PTStats)



Router.route("kemtai","/p/x/gpu", PartnerPageGPUX)
Router.route("kemtai","/p/:partner/gpu", PartnerPageGPU)

Router.route("kemtai","/p/x/ex", ExerciseStatsX)
Router.route("kemtai","/p/:partner/ex", ExerciseStats)

Router.route("kemtai","/b2c-users/:partner", UsersB2C, {admin:true})

Router.route("kemtai","/users/:partner", Users, {admin:true})
Router.route("kemtai","/sessions/:puser", UsersSessions, {admin:true})
Router.route("kemtai","/users/:partner/:puser", UsersSessions, {admin:true})

Router.route("kemtai","/exhistory/:puser", ExHistory)
Router.route("kemtai","/session/:session", Session, {admin:true, page:'Session'})
Router.route("kemtai","/story/:session", Story)
Router.route("kemtai","/questionnaire", QuestionnaireFunnel)
Router.route("kemtai","/demo/users", DemoUsers)
Router.route("kemtai","/recordings", Recordings)
Router.route("kemtai","/monitoring", Monitoring)

Router.route("kemtai","/", MainPage)
Router.route("kemtai","/now", ActiveSessions)
Router.route("kemtai","/today", ActivitySummary)


Router.defaultRoute = "/"

defineVoriRoutes(Router)
}


